import React from 'react';
import { OurClientsStyle } from './style';
import { ourClients } from '@/public/assets/clients';
import { motion } from 'framer-motion';

const fadeInAnimationVariants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index,
    },
  }),
};

function OurClients() {
  return (
    <OurClientsStyle>
      <motion.div
        className="text"
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
      >
        <h2 className="title">Trusted by 200+ Businesses</h2>
        <div className="subText">Since launching in 2021, over 200 people have used Filmmakers Mart to find services ranging from Locations, Logistics and Talents.</div>
      </motion.div>
      <div className="clientLogos">
        {ourClients.map((client, index) => (
          <motion.div
            className="clientLogoWrapper"
            key={index}
            variants={fadeInAnimationVariants}
            initial='initial'
            whileInView='animate'
            viewport={{
              once: true,
            }}
            custom={index}
          >
            {client.logo}
          </motion.div>
        ))}
      </div>
    </OurClientsStyle>
  );
}

export default OurClients;