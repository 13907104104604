export const testimonialOne = [
  {
    companyName: 'Jaekel House',
    content:
      'Filmmakers Mart has been consistent in bringing the filming community to film in our spaces and they have been professional in their dealings. Their donations has helped legacy goals and objectives which includes but not limited to environmental protection and preservation of historical sites',
    authorName: 'Jaekel House',
    authorPosition: 'Service Provider',
  },
  {
    companyName: 'Cleaneat Integrated Services',
    content:
      ' Working with Filmmakers Mart was nothing short of amazing and hitch free. An absolute best in Professionalism, Promptness, and Transparency.',
    authorName: 'Cleaneat IS',
    authorPosition: 'Service Provider',
  },
  {
    companyName: 'HOZSHORTLET',
    content:
      'I have worked with Filmmakers Mart for a while now and trust me it has been a fantastic experience so far. The staff are well trained, organized and very straightforward. I don’t particularly appreciate taking movie bookings but you guys make my work a lot easier and fun.',
    authorName: 'HOZSHORTLET ',
    authorPosition: 'Service Provider',
  },
  {
    companyName: 'Trebet Superstores Lekki',
    content:
      'My experience with Filmmakers Mart has been a wonderful and memorable one. They ensure their clients adhere to the rules and regulations of the stores. They have been very professional in their dealings with us and we look forward to working with them more in the future.',
    authorName: 'Trebet Superstores',
    authorPosition: 'Business Manager',
  },
  {
    companyName: 'Terrence',
    content:
      'Working and opening my apartment to Filmmakers Mart has been nothing short of great. I commend how involved they are from the beginning of the process till the end (pre-shoot, shoot day and post-shoot day); it’s incredibly welcoming. They are indeed a solution-oriented organization and I am glad to work with them',
    authorName: 'Terrence ',
    authorPosition: 'Property Owner',
  },
];

export const testimonialTwo = [
  {
    companyName: 'Trino Studio',
    content:
      'The first thoughts after engaging with Filmmakers Mart are innovative solutions. They understand the importance of finding the right filming locations and providing needed support for filmmakers.',
    authorName: 'Trino Studio',
    authorPosition: 'Filmmaker',
  },
  {
    companyName: 'FilmTrybe Productions',
    content:
      'Your team is very involved. You’re there through the entire ride, and so I don’t feel left alone. Your team also does a little magic, that’s the only way to explain how you land locations that are impossible to land.',
    authorName: 'FilmTrybe Productions',
    authorPosition: 'Filmmaker',
  },
  {
    companyName: 'FilmTrybe',
    content:
      "Filmmakers Mart is very involved and present throughout the entire production and as such, the client doesn't feel alone. Your team does magic. That is the only explanation for how you land locations that are impossible to land.",
    authorName: 'Ifeoluwa ',
    authorPosition: 'Filmmaker',
  },
  {
    companyName: 'Alien Visuals',
    content:
      'I am so much at peace now. Locations are the last things I worry about while in production. Your team members are so easy to access and communicate with. I state my needs and they are met with accuracy and precision. I am glad I came across Filmmakers Mart.',
    authorName: 'Tomiwa ',
    authorPosition: 'Filmmaker',
  },
  {
    companyName: 'LAMEUR MEDIA',
    content:
      'It was such a nice experience working with you guys. I salute professionalism and calm.',
    authorName: 'Ifedola ',
    authorPosition: 'Filmmaker',
  },
];
