import React from 'react';
import { TestimonialStyle } from './style';
import { motion } from 'framer-motion';
import TestimonialCarousel from '../testimonial/carousel';
import { testimonialOne, testimonialTwo } from '../../lib/data';


function Testimonials() {
  return (
    <TestimonialStyle>
      <motion.div
        className="headerWrapper"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.75, delay: 0.25 } }}
        viewport={{ once: true }}
        id='testimonials'
      >
        <h1 className="sectionTitle">We’re Proud of our Customer’s Success</h1>
        <p className="sectionSubText">Here’s what they have to say about Filmmakers mart.</p>
      </motion.div>
      <div className="testimonialsWrapper lgScreen">
        <TestimonialCarousel data={testimonialOne} direction={'left'} />
        <TestimonialCarousel data={testimonialTwo} direction={'right'} />
      </div>
      <div className="testimonialsWrapper smScreen">
        <TestimonialCarousel data={[...testimonialOne, ...testimonialTwo]} direction={'left'} animDuration={50} />
      </div>
    </TestimonialStyle>
  );
}

export default Testimonials;