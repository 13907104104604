import CarouselOne from './carousel1.jpg';
import CarouselTwo from './carousel2.jpg';
import CarouselThree from './carousel3.jpg';
import CarouselFour from './carousel4.jpg';
import CarouselFive from './carousel5.jpg';
import CarouselSix from './carousel6.jpg';
import CarouselSeven from './carousel7.jpg';
import CarouselEight from './carousel8.jpg';
import CarouselNine from './carousel9.jpg';
import CarouselTen from './carousel10.jpg';
import CarouselEleven from './carousel11.jpg';
import CarouselTwelve from './carousel12.jpg';
import CarouselThirteen from './carousel13.jpg';

export {
  CarouselOne,
  CarouselTwo,
  CarouselThree,
  CarouselFour,
  CarouselFive,
  CarouselSix,
  CarouselSeven,
  CarouselEight,
  CarouselNine,
  CarouselTen,
  CarouselEleven,
  CarouselTwelve,
  CarouselThirteen,
};
