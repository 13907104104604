import React from 'react';
import { OurServicesStyle } from './style';
import { FMMTheme } from '../theme';
import MovieWheel from '@/public/assets/svg/movie_wheel.svg';
import SectionTag from './sectionTag';
import { MapsLocation01Icon, TruckIcon, UserMultipleIcon } from '../hugeIcons';
import { motion } from 'framer-motion';


const ServiceCard = ({ title, text, icon, count }: { title: string, text: string, icon: React.ReactNode, count: number }) => {
  return (
    <motion.div className="serviceCard" initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.5 * count, duration: 0.5 } }} viewport={{ once: true }}>
      <div className="iconWrapper"><div className="icon">{icon}</div></div>
      <div className="cardTitle">{title}</div>
      <div className="cardSubText">{text}</div>
    </motion.div>
  );
};

function OurServices() {
  return (
    <OurServicesStyle>
      <div className="top">
        <motion.div className="servicesText" initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0 }} viewport={{ once: true }}>
          <SectionTag label='What we offer' $className='tag' />
          <h2 className="title">All the services your production needs.</h2>
          <p className="subText">We provide all the services you need for your production ranging from Location, Logistics and Talents.</p>
        </motion.div>
        <motion.div
          className="filmWheel"
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <motion.div
            initial={{ opacity: 0, rotate: 0 }}
            whileInView={{ opacity: [1, 0.5, 1], transition: { delay: 1.5, duration: 1.5, repeat: Infinity } }}
            viewport={{ once: true }}
          >
            <MovieWheel className='wheel' color={FMMTheme.colors.primary_500} />
          </motion.div>
        </motion.div>
      </div>
      <div className="serviceCards">
        <ServiceCard title="Location" text="Filmmakers Mart makes location scouting easy. Get access to verified locations, from certified property managers to film studios, to breathtaking landscapes. Our rich database simplifies remote location scouting and physical recce, ensuring the ideal shoot experience." icon={<MapsLocation01Icon className='icon' />} count={1} />
        <ServiceCard title="Logistics" text="Filmmakers Mart simplifies the logistics management process to ensure that your shoot runs smoothly. We offer logistics services ranging from food, accommodation, transportation, trailers, mobile toilets, equipment, tent, crafts, shortlet." icon={<TruckIcon className='icon' />} count={2} />
        <ServiceCard title="Talents" text="At Filmmakers Mart, we believe in discovering talents in the African film industry. We take pride in connecting you with passionate and talented cast and crew members to help bring your projects to life." icon={<UserMultipleIcon className='icon' />} count={3} />
      </div>
    </OurServicesStyle>
  );
}

export default OurServices;