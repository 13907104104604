import React, { useEffect, useState } from 'react';
import MktButton from '../button';
import { animate, AnimationPlaybackControls, motion, useMotionValue } from 'framer-motion';
import useMeasure from 'react-use-measure';
import HeroBackground from '@/public/assets/svg/hero-background.svg';
import HeroMap from '@/public/assets/svg/hero-map.svg';
import { HeroStyle } from './style';
import SectionTag from './sectionTag';
import { CarouselEight, CarouselEleven, CarouselFive, CarouselFour, CarouselNine, CarouselOne, CarouselSeven, CarouselSix, CarouselTen, CarouselThirteen, CarouselThree, CarouselTwelve, CarouselTwo } from '@/public/assets/carousel';
import Image from 'next/image';

function Hero() {
  const images = [CarouselOne, CarouselTwo, CarouselNine, CarouselThree, CarouselFive, CarouselSix, CarouselTen, CarouselEight, CarouselSeven, CarouselEleven, CarouselFour, CarouselTwelve, CarouselThirteen];
  const [ref, { width }] = useMeasure();
  const xTranslation = useMotionValue(0);
  const FAST_DURATION = 50;
  const SLOW_DURATION = 150;
  const [duration, setDuration] = useState(FAST_DURATION);
  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    const finalPosition = -width / 2 - 32;
    let controls: AnimationPlaybackControls;
    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        duration: duration * (1 - xTranslation.get() / finalPosition),
        ease: 'linear',
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        duration: duration,
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'loop',
        repeatDelay: 0,
      });
    }
    return () => controls.stop();
  }, [xTranslation, width, duration, rerender, mustFinish]);

  return (
    <HeroStyle>
      <div className='gridWrapper'>
        <HeroBackground className='grid' />
      </div>
      <div className="mapWrapper">
        <HeroMap className='map' />
      </div>
      <motion.div
        className="heroInfo"
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.25 } }}
        viewport={{ once: true }}
      >
        <SectionTag label='One-stop Marketplace' $className='tag' />
        <h1 className="title" style={{ fontFamily: 'FMMFont !important' }}>Find Everything You Need For Your <span className='highlight'>Next Film Production</span> in <span className='highlight'>Africa.</span></h1>
        <h3 className="subtitle">Welcome to Filmmakers Mart, the number one leading marketplace for everything production.</h3>
        <div className="cta">
          <MktButton label="Explore Services" $type="primary" $maxWidth={180} $className='heroButton' onClick={() => window.location.href = '/marketplace'} />
          <MktButton label="List your Service" $type="secondary" $maxWidth={180} $className='heroButton' onClick={() => window.location.href = 'https://providers.filmmakersmart.com/'} />
        </div>
      </motion.div>
      <div className="carouselWrapper">
        <motion.div
          className="carousel"
          ref={ref}
          style={{ x: xTranslation }}
          onHoverStart={() => {
            setMustFinish(true);
            setDuration(SLOW_DURATION);
          }}
          onHoverEnd={() => {
            setMustFinish(true);
            setDuration(FAST_DURATION);
          }}
        >
          <motion.div className="back" style={{ x: xTranslation }} />
          {[...images, ...images].map((image, index) => (<motion.div className={index % 2 === 0 ? 'smallImage' : 'bigImage'} key={index}>
            <Image src={image} fill alt='' className='carImg' sizes='33vw' placeholder='blur' />
          </motion.div>),
          )}
        </motion.div>
      </div>
    </HeroStyle>
  );
}

export default Hero;