import React from 'react';
import styled from 'styled-components';
import { FMMTheme } from '../theme';

const AvatarStyle = styled.div<{ color?: string, $bgColor?: string, size?: number, $borderColor?: string }>`
  width: ${(props) => `${props.size}px` || '40px'};
  height: ${(props) => `${props.size}px` || '40px'};
  border-radius: 50%;
  background-color: ${(props) => props.$bgColor || FMMTheme.colors.grey_100};
  color: ${(props) => props.color || FMMTheme.colors.grey_500};
  font-weight: ${FMMTheme.fontWeight.bold};
  font-size: ${(props) => props.size ? props.size * 0.8 : '24px'};
  display: grid;
  place-content: center;
  gap: 8px;
`;

function FMMAvatar({ firstName, lastName, $color, $bgColor, size, $borderColor }: { firstName: string, lastName: string, $color?: string, $bgColor?: string, size?: number, $borderColor?: string }) {
  return (
    <AvatarStyle
      color={$color}
      $bgColor={$bgColor}
      size={size}
      $borderColor={$borderColor}>
      {firstName[0]}{lastName[0]}
    </AvatarStyle>
  );
}

export default FMMAvatar;