import { animate, AnimationPlaybackControls, motion, useMotionValue } from 'framer-motion';
import FMMAvatar from '../avatar';
import { useState, useEffect } from 'react';
import useMeasure from 'react-use-measure';
import { TestimonialStyle } from './style';
import { testimonialOne } from '../../lib/data';

const TestimonialCard = ({ companyName, content, authorName, authorPosition }: { companyName: string, content: string, authorName: string, authorPosition: string }) => {

  return (
    <div className='testiCard'>
      <h4 className="companyName">{companyName}</h4>
      <p className="content"><span className='quote'>“</span>{content}<span className='quote'>”</span></p>
      <div className="author">
        <FMMAvatar firstName={authorName.split(' ')[0]} lastName={authorName.split(' ')[1]} $color="#fff" $bgColor="#575E6C" size={48} $borderColor="black" />
        <div className="authorInfo">
          <h3 className="authorName">{authorName}</h3>
          <p className="authorPosition">{authorPosition}</p>
        </div>
      </div>
    </div >
  );
};

const TestimonialCarousel = ({ data, direction, animDuration }: { data: typeof testimonialOne, direction: 'left' | 'right', animDuration?: number }) => {
  const [ref, { width }] = useMeasure();
  const xTranslation = useMotionValue(1);
  const FAST_DURATION = animDuration ?? 25;
  const SLOW_DURATION = 150;
  const [duration, setDuration] = useState(FAST_DURATION);
  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    const finalPosition = direction === 'left' ? -width / 2 - 20 : width / 2 - 20;
    let controls: AnimationPlaybackControls;
    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        duration: duration * (1 - xTranslation.get() / finalPosition),
        ease: 'linear',
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        },
      });
    } else {
      controls = animate(xTranslation, [1, finalPosition], {
        duration: duration,
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'loop',
        repeatDelay: 0,
      });
    }
    return () => controls.stop();
  }, [xTranslation, width, duration, rerender, mustFinish, direction]);

  return (
    <TestimonialStyle direction={direction}>
      <motion.div
        className="carousel"
        ref={ref}
        style={{ x: xTranslation }}
        onHoverStart={() => {
          setMustFinish(true);
          setDuration(SLOW_DURATION);
        }}
        onHoverEnd={() => {
          setMustFinish(true);
          setDuration(FAST_DURATION);
        }}
      >
        <motion.div className="back" style={{ x: xTranslation }} />
        {[...data, ...data].map((testimonial, index) => (<motion.div className={index % 2 === 0 ? 'smallImage' : 'bigImage'} key={index}>
          <TestimonialCard {...testimonial} key={index} />
        </motion.div>),
        )}
      </motion.div>
    </TestimonialStyle>
  );
};

export default TestimonialCarousel;