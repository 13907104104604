import React, { ReactNode } from 'react';
import { OurCommunityStyle } from './style';
import MktButton from '../button';
import { FMMTheme } from '../theme';
import ListServiceIcon from '@/public/assets/join_us/list_service.svg';
import AgreeContractIcon from '@/public/assets/join_us/agree_contract.svg';
import ReceiveBookingIcon from '@/public/assets/join_us/receive_booking.svg';
import GetPaidIcon from '@/public/assets/join_us/get_paid.svg';
import GetSeenIcon from '@/public/assets/join_us/get_seen.svg';
import SetProfileIcon from '@/public/assets/join_us/set_profile.svg';
import { motion } from 'framer-motion';

const BenefitCard = ({ img, title, subText, index }: { img: ReactNode, title: string, subText: string, index: number }) => {
  return (
    <motion.div
      className='benefitCard'
      initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }}
      whileInView={{ opacity: 1, x: 0, transition: { duration: 0.75, delay: 0.75 } }}
      viewport={{ once: true }}
    >
      <div className="benefitIcon">{img}</div>
      <div className="cardText">
        <h4 className="cardTitle">{title}</h4>
        <p className="cardSubText">{subText}</p>
      </div>
    </motion.div>
  );
};

function OurCommunity() {
  return (
    <OurCommunityStyle>
      <motion.div
        className="communityHeader"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1, transition: { duration: 0.75 } }}
        viewport={{ once: true }}
      >
        <h2 className="sectionTitle">Join the Filmmakers Mart Community</h2>
        <p className="sectionSubText">Grow your Network. Get Connected. Earn Money.</p>
      </motion.div>
      <div className="communitySignUp provider" style={{ backgroundColor: `${FMMTheme.colors.violet_50}` }}>
        <div className="signUpHeader">
          <motion.div
            className='textWrapper'
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.75 } }}
            viewport={{ once: true }}
          >
            <h3 className="signUpTitle">Are you a Service Provider?</h3>
            <div className="signUpSubText lgScreen">Are you a service provider? Join the Filmmakers Mart community and gain access to a wide network of clients and opportunities to grow your business. Whether you offer filming locations, logistics services, equipment rentals, or any other essential services in film production, our platform connects you with filmmakers across Africa who are looking for reliable and experienced professionals like yourself.</div>
            <div className="signUpSubText smScreen">Are you a service provider? Join the Filmmakers Mart community and gain access to a wide network of clients and opportunities to grow your business.</div>
          </motion.div>
          <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.75, delay: 0.75 } }} viewport={{ once: true }} className='signupBtnWrapper'>
            <MktButton label='Sign Up' $type='primary' $maxWidth={180} $className='signUpBtn' onClick={() => window.location.href = '/signup'} />
          </motion.div>
        </div>
        <div className="benefitsWrapper">
          <BenefitCard img={<ListServiceIcon className='icon' />} title='List your service' subText='Onboard your service, complete with pricing and availability. Our platform allows you to receive reviews and ratings, build a solid reputation, and give filmmakers the confidence to book your services. Join us now and get closer to your potential clients.' index={0} />
          <BenefitCard img={<AgreeContractIcon className='icon' />} title='Agree to Contract' subText='Our contract helps eliminate any miscommunication or disputes that may arise during the project, ensuring that each party can focus on the task at hand without worrying about legal issues. Rest assured, we work to ensure that contracts are fair and equitable for all.' index={1} />
          <BenefitCard img={<ReceiveBookingIcon className='icon' />} title='Receive Bookings' subText="You'll receive notifications when a request is made, and our platform allows you to easily view and manage your bookings, including the project details, timeline, and payment. You can also communicate with clients throughout the project, ensuring that everything runs smoothly." index={2} />
          <BenefitCard img={<GetPaidIcon className='icon' />} title='Get Paid' subText="Our platform provides various payment options, including credit cards, bank transfers, and online payment gateways, so you can choose the option that best suits you. Once the client makes the payment, we'll process it and transfer it to your account, minus our commission fee." index={3} />
        </div>
      </div>
      <div className="communitySignUp" style={{ backgroundColor: `${FMMTheme.colors.teal_50}` }}>
        <div className="signUpHeader">
          <motion.div
            className='textWrapper'
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 0.75 } }}
            viewport={{ once: true }}>
            <h3 className="signUpTitle">Are you a Cast or Crew member?</h3>
            <div className="signUpSubText">Unlock exciting opportunities to work on diverse film projects in Africa. As a platform connecting filmmakers with verified services, we also help connect you with casting calls and job postings for various roles in the film industry. Whether you are an actor, cinematographer, production assistant, or any other role within the film production realm, Filmmakers Mart is your gateway to connecting with passionate filmmakers and showcasing your skills.</div>
          </motion.div>
          <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.75, delay: 0.75 } }} viewport={{ once: true }} className='signupBtnWrapper'>
            <MktButton label='Sign Up' $type='primary' $maxWidth={180} onClick={() => window.location.href = '/signup'} />
          </motion.div>
        </div>
        <div className="benefitsWrapper">
          <BenefitCard img={<ListServiceIcon className='icon' />} title='Sign Up' subText="Sign up with Filmmakers Mart and join our community of talented cast and crew members. Whether you're an experienced professional or just starting out, we welcome individuals with passion and talent to join us. Sign up today and start connecting with filmmakers looking for your unique skills." index={0} />
          <BenefitCard img={<SetProfileIcon className='icon profileIcon' />} title='Set up your Profile' subText='Set up your profile on Filmmakers Mart to showcase your skills, qualifications, and portfolio. Highlight your previous work, training, and expertise in the film industry. Upload your headshots and showreel to give filmmakers a glimpse of your capabilities.' index={1} />
          <BenefitCard img={<GetSeenIcon className='icon' />} title='Get Seen' subText="With your profile set up, you' ll increase your visibility and be seen by filmmakers actively searching for cast and crew members. Our platform allows filmmakers to browse profiles, search for specific talents, and post casting calls or job opportunities." index={2} />
          <BenefitCard img={<GetPaidIcon className='icon' />} title='Get Paid' subText='When you land a project through our platform, you can be rest assured that getting paid is a smooth process. Filmmakers Mart handles the payment transactions, ensuring that you receive your payment promptly and you can easily track your earnings and manage your financial information.' index={3} />
        </div>
      </div>
    </OurCommunityStyle>
  );
}

export default OurCommunity;