import React from 'react';
import { GetStartedStyle } from './style';
import SectionTag from './sectionTag';
import { motion } from 'framer-motion';

const GetStartedText = ({ index, title, subText, handleGetStarted }: { index: number, title: string, subText: string, handleGetStarted: () => void }) => {
  return (
    <motion.div className="getStartedText" initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 0.1 * index, duration: 0.75 } }} viewport={{ once: true }}>
      <div className="numberWrapper">
        <div className="number">{index}</div>
      </div>
      <div className="infoWrapper">
        <h3 className="title">{title}</h3>
        <p className="subText">{subText}</p>
        <button className="getStartedBtn" onClick={handleGetStarted}>Get Started</button>
      </div>
    </motion.div>
  );
};

const GetStartedVideo = ({ index, url }: { index: number, url: string }) => {
  return (
    <motion.div className="getStartedImage" initial={{ opacity: 0, x: index % 2 === 0 ? -100 : 100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 0.1 * index, duration: 0.75 } }} viewport={{ once: true }}>
      <video src={url} width={100} height={100} controls></video>
    </motion.div>
  );
};

function GetStarted() {
  return (
    <>
      <GetStartedStyle>
        <div className="getStartedHeader">
          <SectionTag label='How we operate' $className='tag' />
          <h2 className="sectionTitle">Get Started from Here</h2>
          <p className="sectionSubText">Find exactly everything you need for your production on Filmmakers mart at a go.</p>
        </div>
        <div className="actionsWrapper">
          <div className="action reverse">
            <GetStartedVideo index={0} url="/assets/video/search.mp4" />
            <GetStartedText index={1} title='Search and book for a service' subText="On the marketplace, you can browse through a wide range of verified services for every aspect of your film production. From location to equipment rentals, you'll find all the services you need in one convenient place. Once you've found the right service, simply book it directly through our platform." handleGetStarted={() => window.location.href = '/marketplace'} />
          </div>
          <div className="action">
            <GetStartedText index={2} title='Submit Production Request' subText="Provide detailed information about your project, including the synopsis, timeline, and other specific requirements. Once you submit your request, you can expect to receive an itemized invoice outlining the cost of the services you've requested. This process eliminates the need for tedious back-and-forth." handleGetStarted={() => window.location.href = '/brief'} />
            <GetStartedVideo index={1} url="/assets/video/request.mp4" />
          </div>
          <div className="action reverse">
            <GetStartedVideo index={2} url="/assets/video/payment.mp4" />
            <GetStartedText index={3} title='Make Payment' subText="We provide multiple payment options to suit your convenience, including credit cards, bank transfers, and online payment gateways. Our secure payment system ensures that your financial information is protected at all times. Once the payment is made, you'll receive a confirmation of your transaction." handleGetStarted={() => window.location.href = '/marketplace'} />
          </div>
          <div className="action">
            <GetStartedText index={4} title='Manage Bookings' subText="Managing your bookings is made easy with Filmmakers Mart. You can effortlessly keep track of all your bookings, view detailed information about each service, and easily make any necessary changes or cancellations on your dashboard." handleGetStarted={() => window.location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}/dashboard`} />
            <GetStartedVideo index={3} url="/assets/video/booking.mp4" />
          </div>
        </div>
      </GetStartedStyle>
    </>
  );
}

export default GetStarted;