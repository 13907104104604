import Accelerate from './accelerate.svg'
import BlinkStudio from './blink_studio.svg'
import CrazyGiggle from './crazy_giggle.svg'
import Dstv from './dstv.svg'
import Family from './family.svg'
import FilmTribe from './film_tribe.svg'
import GreohStudio from './greoh_studio.svg'
import Lucid from './lucid.svg'
import OrangeCulture from './orange_culture.svg'
import TalentX from './talentX.svg'
import TechCity from './techcity.svg'
import TrinoStudio from './trino.svg'
import TwoShoes from './two_shoes.svg'
import Zero from './zero.svg'

export const ourClients = [
  {
    logo: <TalentX className='clientLogo' />,
    name: 'TalentX'
  },
  {
    logo: <FilmTribe className='clientLogo' />,
    name: 'Film Tribe'
  },
  {
    logo: <CrazyGiggle className='clientLogo' />,
    name: 'The Crazy Giggle'
  },
  {
    logo: <TechCity className='clientLogo' />,
    name: 'TechCity'
  },
  {
    logo: <BlinkStudio className='clientLogo' />,
    name: 'Blink Studios'
  },
  {
    logo: <Family className='clientLogo' />,
    name: 'Family'
  },
  {
    logo: <TrinoStudio className='clientLogo' />,
    name: 'Trino Studios'
  },
  {
    logo: <Accelerate className='clientLogo' />,
    name: 'Accelerate'
  },
  {
    logo: <Zero className='clientLogo' />,
    name: 'Zero'
  },
  {
    logo: <Dstv className='clientLogo' />,
    name: 'Dstv'
  },
  {
    logo: <OrangeCulture className='clientLogo' />,
    name: 'Orange Culture'
  },
  {
    logo: <Lucid className='clientLogo' />,
    name: 'Lucid'
  },
  {
    logo: <TwoShoes className='clientLogo' />,
    name: 'Two Shoes Production'
  },
  {
    logo: <GreohStudio className='clientLogo' />,
    name: 'Greoh Studios'
  },
]