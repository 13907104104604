import React from 'react';
import { RequestStyle } from './style';
import MktButton from '../button';
import BriefGrid from '@/public/assets/brief/grid.svg';
import PermitGrid from '@/public/assets/permit/permitGrid.svg';
import { motion } from 'framer-motion';
import NoiseOverlay from '@/public/assets/svg/noise_overlay.svg';
import { ArrowRight02Icon, LinkSquare02Icon } from '@hugeicons/react-pro';

function RequestSection() {
  return (
    <RequestStyle >
      <div className="brief">
        <div className="leftSection">
          <motion.div initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 0.5, duration: 0.5 } }} viewport={{ once: true }} className='headerWrapper'>
            <h2 className="sectionTitle">Can’t Find What You’re Looking For?</h2>
            <p className="sectionSubText">If you can’t find it. We can still find it.</p>
            <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 1, duration: 0.5 } }} viewport={{ once: true }}>
              <MktButton label='Send a Brief' $type='primary' $width='150px' $height={44} $className='briefBtn' suffixIcon={<ArrowRight02Icon size={24} />} onClick={() => window.location.href = '/brief'} />
            </motion.div>
          </motion.div>
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { delay: 0.5, duration: 0.75 } }} viewport={{ once: true }}>
            <BriefGrid className='gridImage' />
          </motion.div>
          <NoiseOverlay className='noiseOverlay' />
          <motion.div
            className='graphicsImageWrapper'
            initial={{ opacity: 0, y: 100, x: '0' }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.75, duration: 0.75 } }} viewport={{ once: true }}
          >
            <div className='graphicsImage' />
          </motion.div>
        </div>
        <motion.div className="rightSection" initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 0.5, duration: 0.5 } }} viewport={{ once: true }}>
          <motion.h3
            initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 1.25, duration: 0.5 } }} viewport={{ once: true }} className="imageText"
          >Spend less time searching, more time <span
            className={'italicPlayfair'}
          >creating</span>.</motion.h3>
        </motion.div>
      </div>
      <div className="permit reverse">
        <motion.div className="leftSection" initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 0.5, duration: 0.5 } }} viewport={{ once: true }}>
          <motion.h3
            initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 1.25, duration: 0.5 } }} viewport={{ once: true }} className="imageText"
          >Anywhere you intend to shoot. We’d handle the paperwork.</motion.h3>
        </motion.div>
        <div className="rightSection">
          <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { delay: 0.5, duration: 0.75 } }} viewport={{ once: true }}>
            <PermitGrid className='gridImage' />
          </motion.div>
          <NoiseOverlay className='noiseOverlay' />
          <motion.div initial={{ opacity: 0, x: -100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 0.5, duration: 0.5 } }} viewport={{ once: true }} className='headerWrapper permitText'>
            <div className='textWrapper'>
              <h2 className="sectionTitle">We Can Pull Up Permits for your Locations.</h2>
              <p className="sectionSubText">Send us a request and we’d be at your service immediately. brief.</p>
            </div>
            <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 1, duration: 0.5 } }} whileHover={{ scale: 1.1 }} viewport={{ once: true }} className='btnWrapper' onClick={() => window.location.href = '/permit'}> <LinkSquare02Icon className='btnIcon' />
            </motion.div>
          </motion.div>
          <motion.div className="permitImages" initial={{ opacity: 0, y: 100 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.5, duration: 0.5 } }} viewport={{ once: true }}>
            <motion.div className='permitNotification' initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0, transition: { delay: 1, duration: 0.5 } }} viewport={{ once: true }} />
            <motion.div className='permitImg1' />
            <motion.div className='permitImg2' initial={{ x: 100 }} whileInView={{ x: 0, transition: { delay: 1.5, duration: 0.5 } }} viewport={{ once: true }} />
          </motion.div>
        </div>
      </div>
    </RequestStyle >
  );
}

export default RequestSection;