import styled from 'styled-components';
import { FMMTheme } from '../components/theme';

export const LandingStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: ${FMMTheme.colors.grey_100};
  padding-top: 96px;
  z-index: 0;
  font-family: 'FMMFont', sans-serif !important;

  /* disable scrollbar on all browsers */
  ::-webkit-scrollbar {
    display: none;
  }
  margin: 0;

  .heroSection {
    width: 100%;
    height: 945px;
    border: 1px solid ${FMMTheme.colors.grey_900};
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }
`;
