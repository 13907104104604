import React from 'react';
import styled from 'styled-components';
import { FMMTheme } from '../theme';

const SectionTagStyle = styled.div`
  width: fit-content;
  padding: 6px 16px;
  border-radius: 8px;
  background-color: ${FMMTheme.colors.primary_100};
  color: #000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.56px;

  @media (max-width: 1024px){
  }
  @media (max-width: 576px){
    padding: 6px 12px;
  }
`;

function SectionTag({ label, $className }: { label: string, $className?: string }) {
  return (
    <SectionTagStyle className={$className}>{label}</SectionTagStyle>
  );
}

export default SectionTag;